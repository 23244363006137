import { axiosProviderNode } from "../../../../../services/http/httpService";

async function getEncryptionKey() {
  try {
    const transactionNodeService = "maxappsDesigner/getMonitorngConfig";

    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data?.content?.config || null;

  } catch (error) {
    return false;
  }
}

export default getEncryptionKey;
