import { useEffect } from 'react';
import fetchSysInfo from '../../views/Monitor/components/Dashboard/services/fetchSysInfo';

import { axiosProviderNode } from './httpService';
import { Logout } from '../../components/NavBar/services';
import { Navigate } from 'react-router-dom';
import { services } from '../../views/Installer/services/configVariables';


const useSystemInfoLogger = (store) => {
    useEffect(() => {
        const fetchData = async () => {
            let response;
            try {
                const transactionNodeService = services.NODE_ENDPOINT_CHECK_AVAILABLE_CONFIG

                 response = await axiosProviderNode.get(transactionNodeService)
                return response;
            }
            catch (error) {
                if (error.response.data?.content?.code == "ERR_JWT_EXPIRED") {
                    localStorage.removeItem("authorization");
                    store.dispatch({
                      type: "dashboard/updateLoginStatus",
                      payload: {
                        isLoggedin: false,
                      },
                    });
                    Navigate("/");
                }  
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 5000);

        return () => clearInterval(interval);
    }, []);
};

export default useSystemInfoLogger;
