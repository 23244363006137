import { jwtDecrypt } from "jose"; // Import decodeJwt from jose
import { Buffer } from "buffer";
import getEncryptionKey from "./fetchSecureKey";
async function getMonitorCredentials() {
  const authorization = localStorage.getItem("authorization");

  if (!authorization) {
    throw new Error("Authorization token not found in localStorage");
  }

  const token = authorization.split("Bearer: ")[1]?.trim();

  if (!token) {
    throw new Error("Token is not in the expected format");
  }

  try {
    const SecureKey = await getEncryptionKey()
    const key = Buffer.from(SecureKey, "hex");
    const { payload } = await jwtDecrypt(token, key);
    return payload; 
  } catch (error) {
    throw new Error("Failed to decode token")
  }
}

export default getMonitorCredentials;
