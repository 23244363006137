import CryptoJS from "react-native-crypto-js";
import { axiosProviderNode } from "../../../../../services/http/httpService";
import getMonitorCredentials from "./MonitorCredentials";

async function DataCollectionIdResult(dataCollectionId) {
    try {
      const credentials = await getMonitorCredentials();

      const transactionNodeService = `debug/store/imxDataCollection/${dataCollectionId}`;

      const body = {
        u: credentials?.login,
        p: credentials?.password,
      };

      const response = await axiosProviderNode.get(
        transactionNodeService,
        body
      );

      return response?.data?.content?.result;
    } catch (error) {
      console.error(
        "Failed to fetch data collection by dataCollectionId:",
        error
      );
      throw error;
    }
  }
  export default DataCollectionIdResult;
